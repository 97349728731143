import { useMemo } from 'react';

import { formatMessage } from './formatMessage';
import { formatNumber } from './formatNumber';

export const useIntl = () => {
  return useMemo(
    () => ({
      formatMessage,
      formatNumber,
    }),
    []
  );
};

export type IntlShape = ReturnType<typeof useIntl>;
