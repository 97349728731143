export enum TrackingEventName {
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  PRODUCT_LIST_FILTERED = 'Product List Filtered',
  PRODUCT_CLICKED = 'Product Clicked',
  MODEL_CARD_CLICKED = 'Model Card Clicked',
  PRODUCT_CARD_CLICKED = 'Product Card Clicked',
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_ADDED = 'Product Added',
  CHECKOUT_STARTED = 'Checkout Started',
  POSITIVE_LEAD = 'Positive Lead',
  CHECKOUT_LEAD = 'Lead',
  CHECKOUT_STEP_VIEWED = 'Checkout Step Viewed',
  CHECKOUT_STEP_COMPLETED = 'Checkout Step Completed',
  PAYMENT_METHOD_SELECTED = 'Payment Method Selected',
  PAYMENT_INFO_ENTERED = 'Payment Info Entered',
  PAYMENT_PROCESSING_ERROR = 'Payment Processing Error',
  ORDER_COMPLETED = 'Order Completed',
  COUPON_ENTERED = 'Coupon Entered',
  COUPON_APPLIED = 'Coupon Applied',
  COUPON_DENIED = 'Coupon Denied',
  COUPON_REMOVED = 'Coupon Removed',
  DELIVERY_DATE_SELECTED = 'Delivery Date Selected',
  DELIVERY_OPTION_SELECTED = 'Delivery Option Selected',
  CONTACT_US_FORM_SUBMISSION = 'Contact Form Submitted',
  CONTACT_UPDATED = 'Contact Updated',
  B2B_FORM_SUBMISSION = 'B2BFormSubmission',
  B2B_LEAD_GEN_FORM_SUBMISSION = 'Inbound - LeadGen Landing Page Form',
  USER_BEHAVIOUR = 'user-behaviour',
  // Gallery Interactions
  GALLERY_IMAGE_CLICKED = 'Gallery Image Clicked',
  GALLERY_CONTROL_ACTION = 'Gallery Control Action',
  GALLERY_SWIPED = 'Gallery Swiped',
  GALLERY_CLOSED = 'Gallery Closed',
  GALLERY_IMAGE_ZOOMED = 'Gallery Image Zoomed',
  USER_ACCOUNT = 'UserAccount',
  REFERRAL_VOUCHER = 'Referral Voucher Applied',
  PDP_INTERACTION = 'PDP Interaction',
  Error = 'error',
  CHECKOUT_ERROR = 'Checkout Error Occurred',
  REFERRAL_MODAL_OPENED = 'Referral Modal Opened',
  REFERRAL_OPTION_CLICKED = 'Referral Option Clicked',
  COMING_SOON_MODAL_OPENED = 'Coming Soon Modal Opened',
  COMING_SOON_LEAD_SUBMITTED = 'Coming Soon Lead Submitted',
  DIRECT_CHECKOUT_WITHOUT_MODAL = 'Direct Checkout Without Modal',
  DIRECT_CHECKOUT_INELIGIBLE = 'Direct Checkout Ineligible',
  NAVITEM_CLICKED = 'NavItem Clicked',
  LOGGED_IN_HOMEPAGE_VIEWED = 'Logged In Homepage Viewed',
  MOBILE_NAV_OPENED = 'MobileNav Opened',
  MOBILE_NAV_CLOSED = 'MobileNav Closed',
  MOBILE_PORTAL_OPENED = 'MobilePortal Opened',
  MOBILE_PORTAL_CLOSED = 'MobilePortal Closed',
  NEON_CAMPAIGN_VIEWED = 'NEON Campaign Viewed',
  NEON_CAMPAIGN_CLICKED = 'NEON Campaign Clicked',
  CAMPAIGN_CARD_CLICKED = 'Campaign Card Clicked',
  CAMPAIGN_CARD_VIEWED = 'Campaign Card Viewed',
  NEON_VOUCHER_MODULE_VIEWED = 'NEON Voucher Module Viewed',
  NEON_VOUCHER_MODULE_LOGGED_IN = 'NEON Voucher Module Logged In',
  NEON_VOUCHER_MODAL_OPENED = 'NEON Voucher Modal Opened',
  NEON_VOUCHER_MODULE_LINK_CLICKED = 'NEON Voucher Module Link Clicked',
  JOB_AUTO_WHITEPAPER_FORM_SUBMITTED = 'Whitepaper Inquiry Submitted - JobAuto',
  JOB_AUTO_FORM_SUBMITTED = 'Form Submitted - Job Auto',
  ACCOUNT_TYPE_SELECTED = 'Account Type Selected',
  ADDRESS_AUTOCOMPLETE_OPENED = 'Google Address Autocomplete Opened',
  ADDRESS_AUTOCOMPLETE_CLICKED = 'Google Address Autocomplete Clicked',
  ADDRESS_AUTOCOMPLETE_CLOSED = 'Google Address Autocomplete Closed',
  ADDRESS_AUTOCOMPLETE_DISMISSED = 'Google Address Autocomplete Dismissed',
  LEAD_CREATED = 'Lead Created',
  COLOR_SELECTED = 'Color Selected',
  TERM_SELECTED = 'Term Selected',
  DISTANCE_PACKAGE_SELECTED = 'Distance Package Selected',
  RETENTION_CAMPAIGN_CARD_SHOWN = 'Retention Campaign Card Shown',
  RETENTION_CAMPAIGN_CARD_CLICKED = 'Retention Campaign Card Clicked',
  FILTER_CLICKED = 'Filter Clicked',
  VIDEO_STARTED = 'Video Started',
  VIDEO_PAUSED = 'Video Paused',
  VIDEO_FINISHED = 'Video Finished',
  VIDEO_ERROR = 'Video Error',
  VIDEO_CTA_CLICKED = 'Video CTA Clicked',
  VIDEO_REPLAY_CLICKED = 'Video Replay Clicked',
  CTA_CLICKED = 'CTA Clicked',
  DEDUCTIBLE_OPTION_SAVED = 'Deductible Option Saved',
  TOOLTIP_HOVERED = 'Tooltip Hovered',
  TOOLTIP_LINK_CLICKED = 'Tooltip Link Clicked',
  ESTIMATE_TAX_CLICKED = 'Estimate Tax Clicked',
  COMPARISON_MODAL_SUBMITTED = 'Comparison Modal Submitted',
  COMPARISON_MODAL_FEEDBACK = 'Comparison Modal Feedback Opened',
  COMPARISON_MODAL_CLOSED = 'Comparison Modal Closed',
  REGISTER_CTA_CLICKED = 'Register CTA Clicked',
  ACCOUNT_LOGIN_FAILED = 'Account Login Failed',
  ACCOUNT_CREATION_FAILED = 'Account Creation Failed',
  LOGIN_MODAL_OPENED = 'Login Modal Opened',
  LOGIN_OPTION_FORM_OPENED = 'Login Option Form Opened',
  LOGIN_FORM_OPENED = 'Login Form Opened',
  LOGIN_MODAL_CLOSED = 'Login Modal Closed',
  REGISTER_MODAL_OPENED = 'Register Modal Opened',
  REGISTER_FORM_OPENED = 'Register Form Opened',
  REGISTER_MODAL_CLOSED = 'Register Modal Closed',
  B2B_LOGIN_CLICKED = 'B2B Login Clicked',
  LOGIN_METHOD_CLICKED = 'Login Method Clicked',
  GOOGLE_ONE_TAP_LOGIN_MESSAGE = 'Google One Tap Login Message',
  CAR_UNAVAILABLE_MODAL_OPENED = 'Car Unavailable Modal Opened',
  CAR_UNAVAILABLE_MODAL_CTA_CLICKED = 'Car Unavailable Modal CTA Clicked',
  BANNER_LINK_CLICKED = 'Banner Link Clicked',
  SOURCE_LINK_CLICKED = 'Source Link Clicked',
  PHONE_SUPPORT_CLICKED = 'Phone Support Clicked',
  HELP_OPTION_CLICKED = 'Help Option Clicked',
  FILTER_CATEGORY_CLICKED = 'Filter Category Clicked',
  FILTER_CATEGORY_CLOSED = 'Filter Category Closed',
  FILTER_OPTION_CLICKED = 'Filter Option Clicked',
  FILTER_DRAWER_OPENED = 'Filter Drawer Opened',
  FILTER_TAG_REMOVED = 'Filter Tag Removed',
  FILTER_TAGS_SCROLLED = 'Filter Tags Scrolled',
  FILTERS_CLEARED = 'Filters Cleared',
  DELIVERY_DATE_SAVED = 'Delivery Date Saved',
  ACCORDION_CLICKED = 'Accordion Clicked',
  SUGGESTED_FAQ_CLICKED = 'Suggested FAQ Clicked',
  FAQ_LINK_CLICKED = 'FAQ Link Clicked',
  BASKET_CLICKED = 'Basket Clicked',
  BASKET_VIEWED = 'Basket Viewed',
  FINN_LOGO_CLICKED = 'FINN Logo Clicked',
  FORM_VALIDATION_ERROR = 'Form Validation Error',
  LOGIN_NAVBAR_CLICKED = 'Login NavBar Clicked',
  BUSINESS_ACCOUNT_DETECTED = 'Business Account Detected',
  PAGE_CONTROL_CLICKED = 'Page Control Clicked',
  SPECIAL_OFFER_CLICKED = 'Special Offer Clicked',
  SPECIAL_OFFER_FORM_CLOSED = 'Special Offer Form Closed',
  SPECIAL_OFFER_FORM_SUBMITTED = 'Special Offer Form Submitted',
  DEDUCTIBLE_TYPE_CHANGED = 'Deductible Type Changed',
  ACCOUNT_SWITCHED = 'Account Switched',
  FORM_FIELD_FOCUSED = 'Form Field Focused',
  FORM_FIELD_ENTERED = 'Form Field Entered',
  CHECKBOX_CLICKED = 'Checkbox Clicked',
  LINK_CLICKED = 'Link Clicked',
  LOYALTY_ITEM_CLICKED = 'Loyalty Item Clicked',
  LOYALTY_ITEM_CLOSED = 'Loyalty Item Closed',
  LOYALTY_PAGE_OPENED = 'Loyalty Page Opened',
  LOYALTY_INTRO_MODAL_OPENED = 'Loyalty Intro Modal Opened',
  LOYALTY_INTRO_MODAL_CLOSED = 'Loyalty Intro Modal Closed',
  REDEEMABLE_LOYALTY_ITEM_CLICKED = 'Redeemable Loyalty Item Clicked',
  REDEEMABLE_LOYALTY_ITEM_CLOSED = 'Redeemable Loyalty Item Closed',
  REDEEMABLE_LOYALTY_ITEM_REDEEMED = 'Redeemable Loyalty Item Redeemed',
  MODAL_OPENED = 'Modal Opened',
  MODAL_CLOSED = 'Modal Closed',
  DROPDOWN_CLICKED = 'Dropdown Clicked',
  FILTER_DRAWER_CLOSED = 'Filter Drawer Closed',
  SWAP_OPTION_CLICKED = 'Swap Option Clicked',
  SWAP_DATE_AUTO_SELECTED_FOR_RETENTION = 'Swap Date Auto Selected For Retention',
  DELIVERYDATE_AUTO_SELECTED_FOR_DIRECT_CHECKOUT = 'Delivery Date Auto Selected For Direct Checkout',
  PROLONGATION_OPTION_CLICKED = 'Prolongation Option Clicked',
  FORGOT_PASSWORD_FORM_OPENED = 'Forgot Password Form Opened',
  FORGOT_PASSWORD_SUCCESS_FORM_OPENED = 'Forgot Password Success Form Opened',
  MAGIC_LINK_FORM_OPENED = 'Magic Link Form Opened',
  MAGIC_LINK_SUCCESS_FORM_OPENED = 'Magic Link Success Form Opened',
  MAGIC_LINK_FORM_SUBMITTED = 'Magic Link Form Submitted',
  MAGIC_LINK_FORM_RE_SUBMITTED = 'Magic Link Form Re-submitted',
  FORGOT_PASSWORD_FORM_SUBMITTED = 'Forgot Password Form Submitted',
  FORGOT_PASSWORD_FORM_RE_SUBMITTED = 'Forgot Password Form Form Re-submitted',
  PLP_CARD_VIEWED = 'PLP Card Viewed',
  STRIPE_EXTERNAL_PROVIDER_REDIRECTED = 'Stripe External Provider Redirected',
  CAR_RECOMMENDATIONS_SHOWN = 'Car Recommendations Shown',
  CAR_RECOMMENDATIONS_CLICKED = 'Car Recommendations Clicked',
  SWAP_OPTION_VIEWED = 'Swap Option Viewed',
  SWAP_OPTION_SELECTED = 'Swap Option Selected',
  CTA_SHOWN = 'CTA Shown',
  TEXT_BLOCK_SHOWN = 'Text Block Shown',
  GRID_TOGGLE_CLICKED = 'Grid Toggle Clicked',
  PDP_PARTNER_DISCOUNT_SHOWED = 'PDP Partner Discount Showed',
  PCP_PARTNER_DISCOUNT_SHOWED = 'PCP Partner Discount Showed',
  MANAGE_CAR_SECTION_VIEWED = 'Manage Car Section Viewed',
  MANAGE_CAR_CARD_CLICKED = 'Manage Car Card Clicked',
  COMPONENT_LOADED = 'Component Loaded',
  COMPONENT_VIEWED = 'Component Viewed',
  VERIFICATION_STEP_VIEWED = 'Verification Step Viewed',
  INSURANCE_UPGRADE_OFFER_SHOWED = 'Insurance Upgrade Offer Showed',
  PICKUP_OPTION_SWITCHED = 'Pickup Option Switched',
  CITY_SELECTED = 'City Selected',
  DEDUCTIBLE_OPTION_SELECTED = 'Deductible Option Selected',
  DEDUCTIBLE_OPTION_SUBMITTED = 'Deductible Option Submitted',
  DEDUCTIBLE_OPTION_SUCCESS = 'Deductible Option Success',
  DEDUCTIBLE_OPTION_ERROR = 'Deductible Option Error',
  BACK_BUTTON_CLICKED = 'Back Button Clicked',
  PORTAL_MENU_CLICKED = 'Portal Menu Clicked',
  DEAL_CREATION_BLOCKED_FOR_AGE = 'Deal Creation Blocked For Age',
  MORE_LESS_BUTTON_CLICKED = 'More/Less Button Clicked',
  ADDON_ATTACHED = 'Addon Attached',
}

export enum LinkLocation {
  PDP_BREADCRUMB = 'PDP Breadcrumb',
  BLOG_BREADCRUMB = 'Blog Breadcrumb',
}

export type NavigationTrackingEvent = {
  eventName: TrackingEventName;
  link?: string;
  type?: string;
};

// is_customer false if the customer is not 'signed in' or 'signed in' and has no deals.
// is_customer true if the customer is 'signed in' and has at least one deal (at least SIGNED).
export type HelpCenterTrackingProps = {
  is_customer: boolean;
};

export enum NavItem {
  MY_CARS = 'my_cars',
  MY_PROFILE = 'my_profile',
  TECHNICAL_SUPPORT = 'technical_support',
  BONUS_PROGRAM = 'bonus_program',
  MY_DRIVERS = 'my_drivers',
  LOYALTY = 'loyalty',
  LOG_OUT = 'log_out',
}

export type NavItemEventProps = {
  option: NavItem;
  is_customer: boolean;
};
