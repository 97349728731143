import { Fragment, JSX, ReactNode } from 'react';

import { getCustomTags, getMessages } from './core';
// as we use overloads we need to disable the no-redeclare rule for TS to work
/* eslint-disable no-redeclare */

// partially generated with O1

/*
  This function does the same thing as formatMessage from react-intl.
  Basically it allows you to format a message with placeholders(ICU format).
  formatMessage('hello {name}', { name: 'world' }) => 'hello world'.
  It also supports chunks which are JSX elements.
  formatMessage('hello <el>world</el>', { el: (chunks) => <strong>{chunks}</strong> }) => <strong>hello world</strong>.
*/

/**
  We use messages as singleton. Because server components are stateless
  and does not support context. this pattern copied from next-18n package.
*/

export type CB = (chunks: string) => ReactNode;
export function formatMessage(id: string): string;
export function formatMessage(
  id: string,
  props: Record<string, CB | string | number | boolean>
): JSX.Element;
export function formatMessage(
  id: string,
  props: Record<string, CB | string | number | boolean>,
  config: { noTags: boolean }
): string;
export function formatMessage(
  id: string,
  props?: Record<string, string | number | ((text: string) => ReactNode)>,
  config?: { noTags: boolean }
) {
  let result = getMessages()?.[id] ?? id;

  if (props) {
    // Handle plural formatting segments
    const pluralRegex = /\{(\w+),\s*plural,\s*((?:[^{}]|\{[^{}]+\})+)\}/g;
    result = result?.replace(
      pluralRegex,
      (_match, variableName, conditionsStr) => {
        let value = props[variableName];
        if (typeof value !== 'number') {
          value = Number(value);
        }
        if (isNaN(value)) return '';

        let selectedText = '';
        const conditionRegex = /(=\d+|other)\s*\{([^}]+)\}/g;
        let conditionMatch;
        while ((conditionMatch = conditionRegex.exec(conditionsStr)) !== null) {
          const [, conditionKey, conditionText] = conditionMatch;
          if (conditionKey.startsWith('=')) {
            const conditionNumber = Number(conditionKey.slice(1));
            if (value === conditionNumber) {
              selectedText = conditionText;
              break;
            }
          } else if (conditionKey === 'other') {
            // default case if no exact match
            selectedText = conditionText;
          }
        }

        return selectedText;
      }
    );

    // Process simple variable replacements
    Object.keys(props).forEach((key) => {
      result = result
        ?.replaceAll(
          `{${key}}`,
          (props[key] as string | number)?.toString() ?? ''
        )
        .replaceAll('#', String(props[key]));
    });
  }

  // Process JSX tags as before
  const tagRegex = /<(\w+)(?:>(.*?)<\/\1>|\s*\/>)/g;
  const parts: (string | ReactNode)[] = [];
  let lastIndex = 0;
  let match;

  if (config?.noTags) {
    return result;
  }

  while ((match = tagRegex.exec(result)) !== null) {
    const [fullMatch, tagName, innerText] = match;
    const { index } = match;
    if (index > lastIndex) {
      parts.push(result.substring(lastIndex, index));
    }
    const existingTags = getCustomTags();
    const tags = { ...existingTags, ...props };

    if (tagName && tags[tagName] && typeof tags[tagName] === 'function') {
      parts.push(
        innerText !== undefined ? tags[tagName](innerText) : tags[tagName]('')
      );
    } else {
      parts.push(fullMatch);
    }
    lastIndex = index + fullMatch.length;
  }
  if (lastIndex < result?.length) {
    parts.push(result.substring(lastIndex));
  }
  if (parts.length === 1) return parts[0];

  return (
    <>
      {parts.map((part, index) => (
        <Fragment key={index}>{part}</Fragment>
      ))}
    </>
  );
}
