import { trackEvent } from '@finn/ui-utils';

import { NavigationTrackingEvent, TrackingEventName } from './types';

export const trackingErrorHandler = (fn: () => void) => {
  try {
    fn();
  } catch (err) {
    // ignore
  }
};

export const interactionTrackingEvent = (
  eventName: TrackingEventName,
  eventProperties: object // Used to be `TrackingEventProperties`, but it enforces GA event structure, which is not relevant for tracking in Segment
) => {
  trackingErrorHandler(() => {
    trackEvent(eventName, eventProperties);
  });
};

export const navigationTrackingEvent = ({
  eventName,
  link,
  type,
}: NavigationTrackingEvent) => {
  interactionTrackingEvent(eventName, {
    link,
    type,
  });
};
