import { cn } from '@finn/ui-utils';
import { cva, type VariantProps } from 'class-variance-authority';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
} from 'react';

import { ArrowForwardIos } from '../icons/generated/arrow-forward-ios';
import { Spinner } from '../spinner';

const buttonVariants = cva(
  [
    'relative',
    'inline-flex',
    'border-0 border-black border-solid rounded-sm',
    'bg-transparent',
    'text-black',
    'cursor-pointer',
    'items-center justify-center',
    'whitespace-nowrap',
    'transition-colors',
    'disabled:pointer-events-none disabled:bg-pewter disabled:text-steel disabled:fill-steel',
    // accessability styles
    'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-trustedBlue',
  ],
  {
    variants: {
      variant: {
        primary: [
          'bg-trustedBlue',
          'text-white',
          'fill-white',
          'hover:bg-trustedBluePressed',
          'active:bg-trustedBluePressed',
        ],
        secondary: ['bg-black', 'text-white', 'fill-white'],
        secondaryWhite: [
          'bg-white',
          'hover:bg-snow',
          'fill-black',
          'active:bg-snow',
        ],
        outline: [
          'border',
          'hover:bg-black active:bg-black',
          'hover:text-white active:text-white',
          'hover:fill-white active:fill-white',
          'disabled:border-pewter',
          'disabled:bg-transparent',
        ],
        outlineWhite: [
          'border',
          'border-white',
          'text-white',
          'fill-white',
          'hover:bg-white active:bg-white',
          'hover:text-black acitve:text-black',
          'disabled:border-pewter',
          'disabled:bg-transparent',
        ],
        ghost: ['hover:bg-snow', 'active:bg-snow', 'disabled:bg-transparent'],
        action: [
          'hover:text-iron',
          'hover:fill-iron',
          'active:fill-iron',
          'active:text-iron',
          'disabled:bg-transparent',
        ],
        icon: [
          'border',
          'rounded',
          'fill-black',
          'hover:bg-black active:bg-black',
          'hover:fill-white active:fill-white',
          'disabled:border-pewter',
          'disabled:bg-transparent',
        ],
        iconWhite: [
          'border',
          'border-white',
          'rounded',
          'fill-white disabled:bg-transparent',
          'hover:bg-white active:bg-white',
          'hover:fill-black active:fill-black',
          'disabled:border-pewter',
          'disabled:bg-transparent',
        ],
        circle: [
          'border',
          'rounded-full',
          'fill-black',
          'hover:bg-black active:bg-black',
          'hover:fill-white active:fill-white',
          'disabled:border-pewter',
          'disabled:bg-transparent',
        ],
        circleWhite: [
          'border',
          'border-white',
          'rounded-full',
          'fill-white disabled:bg-transparent',
          'hover:bg-white active:bg-white',
          'hover:fill-black active:fill-black',
          'disabled:border-pewter',
          'disabled:bg-transparent',
        ],
        link: ['hover:text-iron', 'active:text-iron'],
      },
      size: {
        sm: 'h-8 w-max px-4 body-12-semibold [&_svg]:w-4 [&_svg]:h-4',
        md: 'h-10 w-max px-4 body-16-semibold [&_svg]:w-6 [&_svg]:h-6',
        lg: 'h-14 w-max px-8 body-16-semibold [&_svg]:w-6 [&_svg]:h-6',
      },
      // small trick to make disabled state work on achnor tags
      // as by default anchor tags do not support disabled attribute(as html does not support it)
      disabled: {
        true: 'pointer-events-none text-steel fill-steel',
      },
    },
    // for button variant "icon" and "link" the set of sizes is different
    // compared to other buttons, so we need to define a separate compoundVariants for this
    compoundVariants: [
      {
        variant: ['icon', 'iconWhite', 'circle', 'circleWhite'],
        size: 'sm',
        className: 'w-8 p-0',
      },
      {
        variant: ['icon', 'iconWhite', 'circle', 'circleWhite'],
        size: 'md',
        className: 'w-10 p-0',
      },
      {
        variant: ['icon', 'iconWhite', 'circle', 'circleWhite'],
        size: 'lg',
        className: 'w-14 p-0',
      },
      {
        variant: ['link', 'action'],
        size: 'sm',
        className: 'link-12 h-auto p-0',
      },
      {
        variant: ['link', 'action'],
        size: 'md',
        className: 'link-14 h-auto p-0',
      },
      {
        variant: ['link', 'action'],
        size: 'lg',
        className: 'link-16 h-auto p-0',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'lg',
    },
  }
);

// base props defines props that any button able to receive
type BaseProps = {
  href?: string;
  testId?: string;
  disabled?: boolean;
  loading?: boolean;
  withActionIcon?: boolean;
  type?: 'button' | 'submit' | 'reset';
  contentClassName?: string;
} & VariantProps<typeof buttonVariants>;

// ButtonElementProps defines props that <button/> element able to receive
// when button friendly variant is used
type ButtonElementProps = ButtonHTMLAttributes<HTMLButtonElement>;
// LinkElementProps defines props that <a/> element able to receive
// when href is used
type LinkElementProps = AnchorHTMLAttributes<HTMLAnchorElement>;
// BaseButtonProps defines props for any button except link, as for link we want href to be mandatiory
type BaseButtonProps = {
  variant?: Exclude<BaseProps['variant'], 'link'>;
} & ButtonElementProps;
// BaseLinkProps defines props for link button and makes href required if variant link used + sets the rest of link props
type BaseLinkProps = { variant: 'link'; href: string } & LinkElementProps;

// final props, it aggregates all possible props that button can receive +
// specifics for link or button button depending on href availability
export type ButtonProps = BaseProps &
  (BaseLinkProps | (({ href: string } & LinkElementProps) | BaseButtonProps));

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      contentClassName,
      loading,
      withActionIcon,
      variant,
      size,
      children,
      testId,
      type = 'button',
      ...props
    },
    ref
  ) => {
    // we define content that can be showed inside a or button tag
    // the text content needs to be wrapped in another tag, otherwise
    // browser translation will break react's dom structure when loading indicator appears.
    const contentRaw = (
      <>
        {variant === 'action' && withActionIcon && (
          <ArrowForwardIos className="mr-2 max-h-3.5 min-h-3.5 min-w-3.5 max-w-3.5" />
        )}
        <div
          className={cn(
            loading && 'opacity-0',
            'content flex w-full items-center justify-center',
            contentClassName
          )}
        >
          {children}
        </div>
      </>
    );

    // we add support of loading state for this content
    // as we need to hide content, but still render it to keep the layout
    const content = (
      <>
        {contentRaw}
        {loading && (
          <span className={cn('absolute flex items-center justify-center')}>
            <Spinner />
          </span>
        )}
      </>
    );

    // in case if link used or href passed we render <a/> tag
    if ('href' in props && props.href) {
      return (
        <a
          aria-busy={loading}
          data-cy={testId}
          className={cn(
            buttonVariants({
              variant,
              size,
              className,
              disabled: props.disabled,
            }),
            loading ? 'pointer-events-none' : ''
          )}
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          {...(props as LinkElementProps)}
        >
          {content}
        </a>
      );
    }

    return (
      // core DS components can use pure html button
      // eslint-disable-next-line no-restricted-syntax
      <button
        type={type}
        aria-busy={loading}
        className={cn(
          buttonVariants({ variant, size, className }),
          loading ? 'pointer-events-none' : ''
        )}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        data-cy={testId}
        {...(props as BaseButtonProps)}
      >
        {content}
      </button>
    );
  }
);

Button.displayName = 'Button';

export { Button, buttonVariants };
